import React, { useEffect, useState } from 'react';
import {database} from "../firebase";

type CounterData = {
    years: number;
    months: number;
    days: number;
    hours: number;
    minutes: number;
};

const ViewComponent = () => {
    const [counter, setCounter] = useState<CounterData>({
        years: 0,
        months: 0,
        days: 0,
        hours: 0,
        minutes: 0,
    });

    const [isLoading, setIsLoading] = useState(true);
    const [displayedCounter, setDisplayedCounter] = useState<CounterData>({
        years: 0,
        months: 0,
        days: 0,
        hours: 0,
        minutes: 0,
    });

    useEffect(() => {
        const counterRef = database.ref('/');
        counterRef.on('value', (snapshot) => {
            const data = snapshot.val();
            if (data) {
                const totalMinutes = data.years * 365 * 24 * 60 + data.months * 30 * 24 * 60 + data.days * 24 * 60 + data.hours * 60 + data.minutes;

                setIsLoading(true);
                let interval: ReturnType<typeof setInterval>;
                const stepTime = 10; // Adjust the step timing as needed
                const totalSteps = totalMinutes; // Total steps is the converted total minutes
                let step = 0;

                interval = setInterval(() => {
                    let tempMinutes = step;
                    let tempHours = Math.floor(tempMinutes / 60);
                    tempMinutes %= 60;
                    let tempDays = Math.floor(tempHours / 24);
                    tempHours %= 24;
                    let tempMonths = Math.floor(tempDays / 30);
                    tempDays %= 30;
                    let tempYears = Math.floor(tempMonths / 12);
                    tempMonths %= 12;

                    setDisplayedCounter({
                        years: tempYears,
                        months: tempMonths,
                        days: tempDays,
                        hours: tempHours,
                        minutes: tempMinutes,
                    });

                    step++;
                    if (step > totalSteps) {
                        clearInterval(interval);
                        setCounter(data);
                        setIsLoading(false);
                    }
                }, stepTime);
            }
        });
    }, []);


    return (
        <div className={'view'}>
            <h1>Counter</h1>
            {isLoading ? (
                <div className={'time'}>
                    <div>
                        <span className={'white'}>{displayedCounter.minutes}</span>
                        <span> {displayedCounter.minutes <= 1 ? "minute" : "minutes"}</span>
                    </div>
                    <div>
                        <span className={'white'}>{displayedCounter.hours}</span>
                        <span> {displayedCounter.hours <= 1 ? "hour" : "hours"}</span>
                    </div>
                    <div>
                        <span className={'white'}>{displayedCounter.days}</span>
                        <span> {displayedCounter.days <= 1 ? "day" : "days"}</span>
                    </div>
                    <div>
                        <span className={'white'}>{displayedCounter.months}</span>
                        <span> {displayedCounter.months <= 1 ? "month" : "months"}</span>
                    </div>
                    <div>
                        <span className={'white'}>{displayedCounter.years}</span>
                        <span> {displayedCounter.years <= 1 ? "year" : "years"}</span>
                    </div>
                </div>
            ) : (
                <div className={'time'}>
                    <div>
                        <span className={'white'}>{counter.minutes}</span>
                        <span> {counter.minutes <= 1 ? "minute" : "minutes"}</span>
                    </div>
                    <div>
                        <span className={'white'}>{counter.hours}</span>
                        <span> {counter.hours <= 1 ? "hour" : "hours"}</span>
                    </div>
                    <div>
                        <span className={'white'}>{counter.days}</span>
                        <span> {counter.days <= 1 ? "day" : "days"}</span>
                    </div>
                    <div>
                        <span className={'white'}>{counter.months}</span>
                        <span> {counter.months <= 1 ? "month" : "months"}</span>
                    </div>
                    <div>
                        <span className={'white'}>{counter.years}</span>
                        <span> {counter.years <= 1 ? "year" : "years"}</span>
                    </div>
                </div>
            )}
            <img src={'/big-bee.png'} alt={'bee'} />
        </div>
    );
};

export default ViewComponent;
