import firebase from 'firebase/compat/app';
import 'firebase/compat/database';

const firebaseConfig = {
    apiKey: "AIzaSyAdHbdM5XaNw1Gddv4vyWkrNFMaIedfkgc",
    authDomain: "mimi-bee.firebaseapp.com",
    databaseURL: "https://mimi-bee-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "mimi-bee",
    storageBucket: "mimi-bee.appspot.com",
    messagingSenderId: "457358818537",
    appId: "1:457358818537:web:43cfe06d78acfcaf464478"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
export const database = app.database();