import React, {useState} from 'react';
import 'firebase/compat/database';
import {database} from "../firebase";

type CounterData = {
    years: number;
    months: number;
    days: number;
    hours: number;
    minutes: number;
};


const AddComponent = () => {
    let [minutesToAdd, setMinutesToAdd] = useState(0)

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMinutesToAdd(parseInt(event.target.value));
    };
    const updateCounter = (type: String) => {

        database
            .ref('/')
            .once('value')
            .then((snapshot) => {
                const data = snapshot.val() as CounterData;

                let newTotalMinutes =
                    (data.years || 0) * 365 * 24 * 60 +
                    (data.months || 0) * 30 * 24 * 60 +
                    (data.days || 0) * 24 * 60 +
                    (data.hours || 0) * 60 +
                    (data.minutes || 0);


                if (type === 'add') {
                    newTotalMinutes += minutesToAdd;
                } else if (type === 'remove') {
                    newTotalMinutes -= minutesToAdd;
                }

                if (newTotalMinutes < 0) {
                    newTotalMinutes = 0;
                }

                const years = Math.floor(newTotalMinutes / (365 * 24 * 60));
                const months = Math.floor((newTotalMinutes % (365 * 24 * 60)) / (30 * 24 * 60));
                const days = Math.floor((newTotalMinutes % (30 * 24 * 60)) / (24 * 60));
                const hours = Math.floor((newTotalMinutes % (24 * 60)) / 60);
                const minutes = newTotalMinutes % 60;

                const updatedCounter: CounterData = {
                    years,
                    months,
                    days,
                    hours,
                    minutes,
                };

                return updatedCounter;
            })
            .then((updatedCounter) => {
                database.ref('/').set(updatedCounter).then(() => {
                    console.log('Counter updated successfully.');
                });
            })
            .catch((error) => {
                console.error('Error fetching data: ', error);
            });
    };


        return (
            <div>
                <input
                    type="number"
                    value={minutesToAdd.toString()}
                    onChange={handleInputChange}
                />
                <button onClick={() => updateCounter("add")}>Add Minutes</button>
                <button onClick={() => updateCounter("remove")}>Remove Minutes</button>
            </div>
        );
}

export default AddComponent;
