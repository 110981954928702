import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import AddComponent from './Componenent/AddComponent'
import ViewComponent from './Componenent/ViewComponent'
import BeeCanvas from "./Componenent/BeeCanvas";

function App() {

  return (
      <Router>
        <div className="App">
            <BeeCanvas />
            <Routes>
                <Route path="/add" element={<AddComponent />} />
                <Route path="/*" element={<ViewComponent />} />
            </Routes>
        </div>
      </Router>
  );
}

export default App;
