import React, { useEffect, useRef } from 'react';
import beeImage from './bee.png'; // Adjust the path to your image

const BeeCanvas: React.FC = () => {
    const canvasRef = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        const canvas = canvasRef.current!;

        const ctx = canvas.getContext('2d')!;

        const bee = { x: canvas.width / 2, y: canvas.height / 2 };
        const beeImg = new Image();
        beeImg.src = beeImage;

        let counter = 0;

        function draw() {
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            const amplitude = 50; // Adjust the amplitude of the oscillation
            const frequency = 0.02; // Adjust the frequency of the oscillation
            const speed = 2; // Adjust the speed of the bee

            let dx = amplitude * (frequency * counter);
            const dy = canvas.height / 2 + amplitude * Math.sin(frequency * counter);

            if (dx >= canvas.width){
                counter = 0;
            }

            bee.x = dx;
            bee.y = dy;

            counter += speed;

            ctx.beginPath();
            ctx.moveTo(bee.x, bee.y);
            ctx.lineTo(bee.x + 20, bee.y);
            ctx.drawImage(beeImg, bee.x, bee.y, 20, 20);

            requestAnimationFrame(draw);
        }

        draw();
    }, []);

    return <canvas ref={canvasRef} width={window.innerWidth} height={window.innerHeight} className={"canvas-bee"} />;
};

export default BeeCanvas;
